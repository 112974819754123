import { Button, Divider, Form, Input, Modal, notification, QRCode, Spin } from 'antd';
import React, { useState } from 'react';
import { AuthyIcon, DuoAuthIcon, GoogleAuthIcon, MicrosoftAuthIcon } from '../../../assets/Icons';
import { verifyTOTPSetup, setUpTOTP } from 'aws-amplify/auth';
import { CopyOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { fetchUserMFAPreferenceAsync, mfaUpdateForSystemAsync, setMFAPreferenceAsync } from '../../../redux/reducers/slices/UserMFA/UserMFASlice';
import { MFAType, UserTypes } from '../../common/userTypes';
import { useNavigate } from 'react-router';

const TwoFactorAppAuthenticationModal = ({ redirect = false, open = false, setOpen = () => {} }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const userDetails = JSON.parse(localStorage.getItem('admin')) || {};

  const [qr, setQr] = useState('');
  const [secret, setSecret] = useState('');

  // const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [viewAuthenticatorApps, setViewAuthenticatorApps] = useState(true);
  const [finalScanning, setFinalScanning] = useState(false);
  // const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const user_info = JSON.parse(localStorage.getItem('admin')) || {};

  const [qrLoading, setQrLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setViewAuthenticatorApps(true);
    setFinalScanning(false);
    setQr('');
    setSecret('');
    setOpen(false);
    // setSuccess(false);
    form.resetFields();
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    notification.success({
      message: 'Success',
      description: 'Copied.',
    });
  };

  const handleFinish = async (values) => {
    // if (viewConfirmPassword) {
    //   setViewAuthenticatorApps(true);
    //   setViewConfirmPassword(false);
    // }

    if (viewAuthenticatorApps) {
      try {
        setQrLoading(true);
        const totpSetupDetails = await setUpTOTP();
        const appName = 'thepropertyaccountant.com.au';
        const setupUri = totpSetupDetails.getSetupUri(appName);

        const secretMatch = setupUri.search.match(/secret=([^&]*)/);

        if (secretMatch && secretMatch[1]) {
          setSecret(secretMatch[1]);
        }
        setQr(setupUri.href);
        setQrLoading(false);

        setFinalScanning(true);
        setViewAuthenticatorApps(false);
      } catch (error) {
        notification.error({
          message: 'Error',
          description: error.message || 'Something went wrong',
        });
        setQrLoading(false);
        setFinalScanning(false);
      }
    }

    if (finalScanning) {
      try {
        setLoading(true);
        const { code } = values;

        await verifyTOTPSetup({ code });

        await dispatch(setMFAPreferenceAsync('totp'));
        setOpen(false);

        !redirect && (await dispatch(fetchUserMFAPreferenceAsync()));

        await dispatch(mfaUpdateForSystemAsync({ mfa_status: true, mfa_type: MFAType.TOTP_MFA }));

        notification.success({
          message: 'Success',
          description: 'Your account is now protected with 2FA. Enjoy peace of mind knowing your data is safe.',
        });

        setLoading(false);
        form.resetFields();
        setFinalScanning(false);
        setViewAuthenticatorApps(true);

        setQr('');
        setSecret('');

        if (redirect) {
          if (user_info?.user_type === UserTypes.USER) {
            navigate('/referral', { replace: true });
          } else {
            if (![UserTypes.SUB_TAXACCOUTANT, UserTypes.ADMIN_SUB_TAXACCOUNTANT, UserTypes.SUB_CHANNEL_PARTNER].includes(user_info.user_type) && user_info?.company_name === null) {
              navigate('/complete-profile', { replace: true });
            } else {
              navigate('/', { replace: true });
            }
          }
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: error.message || 'Something went wrong',
        });
        setLoading(false);
        // setSuccess(false);
      }
    }
  };

  const goBack = () => {
    form.resetFields();
    setViewAuthenticatorApps(true);
    setFinalScanning(false);
  };

  const authenticatorApps = [
    {
      name: 'Google Authenticator',
      about: 'from Google inc.',
      icon: GoogleAuthIcon,
    },
    {
      name: 'Microsoft Authenticator',
      about: 'from Microsoft Corporation',
      icon: MicrosoftAuthIcon,
    },
    {
      name: 'Authy',
      about: 'from Authy inc.',
      icon: AuthyIcon,
    },
    {
      name: 'Duo Mobile',
      about: 'from Duo Security',
      icon: DuoAuthIcon,
    },
  ];

  return (
    <Modal onCancel={handleCancel} footer={null} style={{ zIndex: '1005', borderRadius: '1em' }} destroyOnClose open={open}>
      <Spin spinning={loading || qrLoading}>
        <Form form={form} layout="vertical" requiredMark={'optional'} className="mt-3" onFinish={handleFinish}>
          {/* {viewConfirmPassword && (
          <div className="mt-4 d-flex flex-column">
            <h2 className="text-center">Enter your password to setup 2FA</h2>
            <Form.Item
              label={<small>Confirm Password</small>}
              labelAlign="center"
              name="password"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item className="d-flex justify-content-center">
              <Button type="primary" htmlType="submit">
                Confirm Password
              </Button>
            </Form.Item>
          </div>
        )} */}

          {viewAuthenticatorApps && (
            <div className="d-flex flex-column gap-1">
              {/* <h5>Install an authentication app on your phone.</h5> */}
              <h5>Install an Authenticator App on Your Mobile Phone</h5>
              <p className="installAuthenticatorText">
                To set up Two-Factor Authentication (2FA) for your account, you'll need to download and install an authenticator app. We recommend the following popular options, Choose the app that
                best suits your device and preferences.
              </p>
              <div className="row">
                {authenticatorApps.map((app) => {
                  const Icon = app.icon;
                  return (
                    <div className="d-flex align-items-center gap-3 ps-3 col-6 row g-2 cursor-default">
                      <div className="d-flex align-items-center gap-1">
                        <Icon />
                        <div className="d-flex flex-column">
                          <span className="p-0 m-0 twoFAHeaderSpan ">{app.name}</span>
                          <span className="text-muted twoFATextSpan"> {app.about} </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mt-5 d-flex justify-content-end gap-4 align-items-center">
                <span className="text-muted cursor-default"> Got your app open? </span>
                <Button type="primary" htmlType="submit">
                  Yes, ready to scan
                </Button>
              </div>
            </div>
          )}

          {finalScanning && (
            <div className="">
              <h5>Use your Authenticator App to Scan the QR Code</h5>
              <div className="d-flex gap-5 pt-2">
                <div>
                  <QRCode size={180} value={qr} />
                </div>
                <div className="w-100">
                  <Form.Item
                    label={<small>Enter the code you see on your authenticator app</small>}
                    labelAlign="center"
                    name="code"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please enter the code!',
                      },
                      {
                        validator: async (_, value) => {
                          if (!value) {
                            return Promise.resolve();
                          }
                          if (!/^\d{6}$/.test(value)) {
                            return Promise.reject(new Error('Code must be exactly 6 digits!'));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      className="mt-2"
                      autoComplete="off"
                      placeholder="Code"
                      maxLength={6}
                      onKeyPress={(e) => {
                        if (e.key === 'Backspace') return;
                        else if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="d-flex justify-content-end gap-2">
                <Button type="button" className="twoFAButton border-0 bg-transparent shadow-none" onClick={() => goBack()}>
                  Back
                </Button>
                <Form.Item>
                  <Button type="primary" className="twoFAButton" htmlType="submit">
                    Set Up
                  </Button>
                </Form.Item>
              </div>
              <Divider className="mb-0 mt-3" />

              <div className="mt-3">
                <p className="text-muted p-0 m-0">Trouble scanning? Enter this key in your authenticator app instead</p>
                <div className="d-flex align-items-center">
                  <small> {secret} </small>
                  <Button icon={<CopyOutlined />} size="small" onClick={() => copyToClipboard(secret)} style={{ marginLeft: 8 }} />
                </div>
              </div>
            </div>
          )}

          {/* {success && (
            <Result
              status="success"
              icon={
                <CheckCircleOutlined
                  style={{
                    color: '#008027',
                  }}
                />
              }
              title={
                <h3
                  style={{
                    color: '#008027',
                  }}
                >
                  Sucessfully enabled!
                </h3>
              }
              subTitle={
                <div>
                  <p>Authenticator app successfully enabled.</p>
                </div>
              }
            />
          )} */}
        </Form>
      </Spin>
    </Modal>
  );
};

export default TwoFactorAppAuthenticationModal;
