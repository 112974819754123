import { Button, Card, List, Modal, Popover, Skeleton, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { getYearList } from '../../../utils';
import { DownloadOutlined, InfoCircleOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { downloadTaxWorkingPaper, getLockYearsList, lockYear } from '../../../redux/actions/AuditTrail/getAuditTrailList.action';
import { useDispatch, useSelector } from 'react-redux';
import { UserTypes } from '../../common/userTypes';
import ModalVerifyOtp from '../../../utils/ModalVerifyOtp';

const Lock = () => {
  const [loading, setLoading] = useState(true);

  const [lockYears, setLockYears] = useState([]);
  const [lockYearsData, setLockYearsData] = useState([]);

  const [lockValue, setLockValue] = useState({});
  const [isOpendelconfirm, setIsOpendelconfirm] = useState(false);

  const [modal, contextHolder] = Modal.useModal();

  const currentClient = localStorage.getItem('currentClient');
  const admin = localStorage.getItem('admin');
  const { user_type = null } = admin ? JSON.parse(admin) : {};

  const [childStatus, setChildStatus] = useState(null);

  const dispatch = useDispatch();
  const getLockYearsListDta = useSelector((state) => state.getLockYearsListReducer);

  const tooltipText = (
    <ul className="fs-6">
      <li> Lock the report once your review is complete to prevent further changes. This helps avoid accidental edits to previous periods.</li>
      <li> You can always unlock the report with OTP anytime to make changes</li>
    </ul>
  );

  useEffect(() => {
    if (!getLockYearsListDta?.data?.result) {
      getLockYears();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLockYears = () => {
    setLoading(true);
    dispatch(getLockYearsList(currentClient)).then((data) => {
      if (data.payload.success) {
        setLockYearsData(data.payload.result || []);
        setLockYears(data.payload.result?.map((item) => item.year) || []);
      }
      setLoading(false);
    });
  };

  const handleLock = (year, status) => {
    // notification for confirm lock
    if (status === 1) {
      modal.confirm({
        title: 'Lock Confirmation',
        content: (
          <>
            <p>
              Once you lock the period (from {`01-07-${year}`} to {`30-06-${year + 1}`}), no edits or changes will be allowed.
            </p>
            <p>This ensures data integrity and control over any future modifications.</p>
            <p>To make changes, you'll need to unlock the report.</p>
          </>
        ),
        icon: <LockOutlined />,
        okText: 'Lock Report',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: () => lockConfirm(year, status),
      });
    }
    // notification for confirm unlock
    if (status === 0) {
      modal.confirm({
        title: 'Confirm Unlock',
        content: 'Are you sure you want to unlock the report?',
        icon: <UnlockOutlined />,
        okText: 'Unlock Report',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: () => lockConfirm(year, status),
      });
    }
  };

  const onLock = (year, status) => {
    setLoading(true);
    dispatch(lockYear(year, status, currentClient)).then((data) => {
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message,
        });
        getLockYears();
      } else {
        notification.error({
          message: 'Failure',
          description: data.message,
        });
      }
      setLoading(false);
    });
  };

  const handledownloadTaxWorkingPaper = async (record) => {
    setLoading(true);
    dispatch(downloadTaxWorkingPaper(currentClient, record.id)).then((data) => {
      if (data.success === true) {
        notification.success({
          message: 'Success',
          description: data.message,
        });
        const link = document.createElement('a');
        link.href = data.result.url;
        link.download = data.result.url;
        link.click();
        setLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: data.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };

  const lockConfirm = (year, status) => {
    setLoading(true);
    setLockValue({ year, status });

    setIsOpendelconfirm(true);

    setChildStatus(status === 1 ? 'lockreport' : 'unlockreport');
  };

  const isDocumentUploaded = (item) => {
    const lockYearData = lockYearsData?.find((el) => el.year === item.value && el.doc_url !== null);

    return lockYearData;
  };

  return (
    <Card
      title={
        <div className="d-flex align-items-center gap-2">
          <span>Lock Report</span>
          <Popover placement="bottom" title={tooltipText}>
            <InfoCircleOutlined className="cursor-pointer" />
          </Popover>
        </div>
      }
      className="box_shadow mt-3"
    >
      <List
        loading={loading}
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={getYearList() || []}
        renderItem={(item) => (
          <List.Item>
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta title={item.label} />
              {lockYears.includes(item.value) && !isDocumentUploaded(item) && user_type !== UserTypes.USER && (
                <Popover content="Reports locked!! Tax working paper would be available for download within 15 mins">
                  <Button className="mr-1" icon={<DownloadOutlined />} type="dashed">
                    Download
                  </Button>
                </Popover>
              )}
              {lockYears.includes(item.value) && isDocumentUploaded(item) && user_type !== UserTypes.USER && (
                <Popover content="Download Tax Working Paper">
                  <Button
                    className="mr-1"
                    icon={<DownloadOutlined />}
                    type="default"
                    // disabled={!lockYearsData.find((el) => el.year === item.value && el.doc_url !== null)}
                    onClick={() => handledownloadTaxWorkingPaper(isDocumentUploaded(item))}
                  >
                    Download
                  </Button>
                </Popover>
              )}
              {!lockYears.includes(item.value) && (
                <Button icon={<UnlockOutlined />} type="primary" onClick={() => handleLock(item.value, 1)}>
                  Lock
                </Button>
              )}
              {lockYears.includes(item.value) && (
                <Button className="property_btn1_icon" icon={<LockOutlined />} onClick={() => handleLock(item.value, 0)}>
                  Unlock
                </Button>
              )}
            </Skeleton>
          </List.Item>
        )}
      />
      {/* `contextHolder` should always be placed under the context you want to access */}
      {contextHolder}

      {/* MODAL delete verify Otp */}

      <ModalVerifyOtp open={isOpendelconfirm} setOpen={setIsOpendelconfirm} onLock={() => onLock(lockValue.year, lockValue.status)} status={childStatus} loading={loading} setLoading={setLoading} />
    </Card>
  );
};

export default Lock;
