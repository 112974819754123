import { postRequest } from '../../../utils/apiUtils';
import { OtpCheckVerificationDelFailure, OtpCheckVerificationDelRequest, OtpCheckVerificationDelSuccess } from '../../reducers/slices/Header/verifyOTP.slice';

export const OtpCheckVerificationDelete = (data) => {
  return async (dispatch) => {
    await dispatch(OtpCheckVerificationDelRequest());
    const { result, error } = await postRequest(`user/otp/verify/deleteuser`, data);

    if (!error) {
      return await dispatch(OtpCheckVerificationDelSuccess(result));
    }
    return await dispatch(OtpCheckVerificationDelFailure(error));
  };
};

export const OtpCheckVerificationCode = (data, type) => {
  return async (dispatch) => {
    await dispatch(OtpCheckVerificationDelRequest());
    const { result, error } = await postRequest(`user/otp/verify/${type}`, data);

    if (!error) {
      return await dispatch(OtpCheckVerificationDelSuccess(result));
    }
    return await dispatch(OtpCheckVerificationDelFailure(error));
  };
};
