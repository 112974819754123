import { getRequest, postRequest } from '../../../utils/apiUtils';
import { getAuditTrailDataFailure, getAuditTrailDataRequest, getAuditTrailDataSuccess } from '../../reducers/slices/AuditTrail/getAuditTrailList.slice';
import { getAuditTrailChartDataFailure, getAuditTrailChartDataRequest, getAuditTrailChartDataSuccess } from '../../reducers/slices/AuditTrail/getAuditTrailChartData.slice';
import { getLockYearsDataFailure, getLockYearsDataRequest, getLockYearsDataSuccess } from '../../reducers/slices/AuditTrail/getLockYearsData.slice';

export const getAuditTrailList = (id, year) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/audit-trail?year=${year}`;
  } else {
    tempURl = `user/audit-trail?userId=${id}&year=${year}`;
  }
  return async (dispatch) => {
    await dispatch(getAuditTrailDataRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getAuditTrailDataSuccess(result));
    }
    return await dispatch(getAuditTrailDataFailure(result));
  };
};

export const getAuditTrailChartData = (id, year) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/audit-trail/chart?year=${year}`;
  } else {
    tempURl = `user/audit-trail/chart?userId=${id}&year=${year}`;
  }
  return async (dispatch) => {
    await dispatch(getAuditTrailChartDataRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getAuditTrailChartDataSuccess(result));
    }
    return await dispatch(getAuditTrailChartDataFailure(result));
  };
};

// post audit trail data
export const postAuditTrailData = (data, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/audit-trail`;
  } else {
    tempURl = `user/audit-trail?userId=${id}`;
  }
  return async () => {
    const { result, error } = await postRequest(tempURl, data);

    if (!error) {
      return result;
    }
    return error;
  };
};

// get lock list
export const getLockYearsList = (id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/audit-trail/lock/list`;
  } else {
    tempURl = `user/audit-trail/lock/list?userId=${id}`;
  }
  return async (dispatch) => {
    await dispatch(getLockYearsDataRequest());
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return await dispatch(getLockYearsDataSuccess(result));
    }
    return await dispatch(getLockYearsDataFailure(result));
  };
};

export const lockYear = (year, status, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/audit-trail/lock?year=${year}&status=${status}`;
  } else {
    tempURl = `user/audit-trail/lock?userId=${id}&year=${year}&status=${status}`;
  }
  return async () => {
    const { result, error } = await postRequest(tempURl);

    if (!error) {
      return result;
    }
    return error;
  };
};

export const resetAuditReport = (year, id) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/audit-trail/reset?year=${year}`;
  } else {
    tempURl = `user/audit-trail/reset?userId=${id}&year=${year}`;
  }
  return async () => {
    const { result, error } = await postRequest(tempURl);

    if (!error) {
      return result;
    }
    return error;
  };
};

// Download tax working peper
export const downloadTaxWorkingPaper = (id, yearId) => {
  let tempURl = ``;
  if (!id) {
    tempURl = `user/tax-paper/doc/${yearId}`;
  } else {
    tempURl = `user/tax-paper/doc/${yearId}?userId=${id}`;
  }
  return async () => {
    const { result, error } = await getRequest(tempURl);

    if (!error) {
      return result;
    }
    return error;
  };
};

export const getRentInputAmount = (property_id, year, currentClient) => {
  return async () => {
    const { result, error } = await getRequest(`user/efoy-list/${property_id}?year=${year}${currentClient ? `&userId=${currentClient}` : ''}`);

    if (!error) {
      return result;
    }
    return error;
  };
};
