import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendOtpCheckVerificationCode } from '../redux/actions/Header/sendOtp.action';
import { OtpCheckVerificationCode } from '../redux/actions/Header/verifyOtp.action';
import { confirmUserAttribute, sendUserAttributeVerificationCode } from 'aws-amplify/auth';

const VerifyOtp = ({
  open = false,
  enterOtp = false,
  setEnterOtp = () => {},
  status = null,
  setLoading = () => {},
  setOpen = () => {},
  setAuthenticatePhone = () => {},
  onLock = () => {},
  onEnterOtp = () => {},
  titleChange = false,
}) => {
  const [otpForm] = Form.useForm();
  const userDetails = JSON.parse(localStorage.getItem('admin')) || {};

  const [otpId, setOtpId] = useState('');
  const [otp, setOtp] = useState(Array(6).fill(''));

  const [incorrectOtp, setIncorrectOtp] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [otpLoading, setOtpLoading] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(30);

  const [resend, setResend] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (status !== 'verifyphone') {
      dispatch(sendOtpCheckVerificationCode(status)).then((res) => {
        if (res.payload.success === true) {
          setOtpId(res.payload.result.otpId);
          setOpen(true);
          setLoading(false);
        } else {
          notification.error({
            message: 'Failure',
            description: res.payload.message || 'Something went wrong!',
          });
          setLoading(false);
        }
      });
    } else {
      async function sendOtp() {
        try {
          await sendUserAttributeVerificationCode({
            userAttributeKey: 'phone_number',
          });
        } catch (error) {
          setLoading(false);
          setIncorrectOtp(true);
          setErrorMessage(error.message || 'Something went wrong!');
        }
      }
      sendOtp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status, resend]);

  useEffect(() => {
    let timer;
    if (enterOtp ? secondsLeft > 0 && enterOtp : secondsLeft > 0 && open) {
      timer = setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [secondsLeft, open, enterOtp]);

  const maskPhoneNumber = (phoneNumber) => {
    let start = phoneNumber.substring(0, phoneNumber.length - 8);
    let end = phoneNumber.substring(phoneNumber.length - 4);

    let middle = 'x'.repeat(4);

    return `${start}${middle}${end}`;
  };

  const handleChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const hasStartedTypingOtp = () => {
    return otp.some((digit) => digit !== '');
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (incorrectOtp) {
        setIncorrectOtp(false);
      }
    }
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const validateOtp = () => {
    return otp.join('').length === 6;
  };

  const handleResend = () => {
    // Add your resend logic here (e.g., API call to resend OTP)
    // After resending, reset the timer
    setResend(true);
    setSecondsLeft(30);
  };

  const handleCancel = () => {
    setSecondsLeft(30); // Reset the timer
    setEnterOtp(true);
    setAuthenticatePhone(true);
    setOpen(false);
    setOtp(Array(6).fill(''));
    setIncorrectOtp(false);
    setErrorMessage('');
  };

  const handleFinish = async () => {
    setOtpLoading(true);

    const finalOtp = otp.join('');
    const values = {
      id: otpId,
      otp: finalOtp,
    };

    if (status !== 'verifyphone') {
      dispatch(OtpCheckVerificationCode(values, status)).then((res) => {
        if (res.payload.success === true) {
          notification.success({
            message: 'Success',
            description: res.payload.message,
          });
          setOtp(Array(6).fill(''));
          setSecondsLeft(30); // Reset the timer
          setOpen(false);
          setOtpLoading(false);
          onLock();
        } else {
          setOtpLoading(false);
          setIncorrectOtp(true);
        }
      });
    } else {
      try {
        await confirmUserAttribute({ userAttributeKey: 'phone_number', confirmationCode: finalOtp });

        setOpen(false);
        setOtpLoading(false);
        onEnterOtp();
      } catch (error) {
        setOtpLoading(false);
        setErrorMessage(error.message || 'Something went wrong!');
        setIncorrectOtp(true);

        // notification.error({
        //   message: 'Failure',
        //   description: error.message || 'Something went wrong!',
        // });
      }
    }
  };

  return (
    <Spin spinning={otpLoading}>
      <Form form={otpForm} layout="vertical" requiredMark={'optional'} className="mt-3" onFinish={handleFinish}>
        <div className="otpInputDiv">
          <Form.Item
            label={
              !titleChange ? (
                <small className="text-center w-100 ms-4">Enter the authentication code below we sent to your registered number.</small>
              ) : (
                <small className="text-center w-100 ms-4">
                  We've sent a 6-digit OTP to your number {maskPhoneNumber(userDetails?.phone_number)}. Please enter it here to verify your account and add an extra layer of security.
                </small>
              )
            }
            validateStatus={hasStartedTypingOtp() ? (validateOtp() && !incorrectOtp ? 'success' : 'error') : undefined}
            help={hasStartedTypingOtp() && !validateOtp() ? <span className="text-center">OTP must be 6 digits</span> : undefined}
            required
            name="otpCode"
            className="w-100 otpInputModal"
          >
            <div className="d-flex justify-content-center gap-2">
              {otp.map((_, index) => (
                <Input
                  key={index}
                  autoComplete="off"
                  id={`otp-input-${index}`}
                  type="text"
                  maxLength={1}
                  value={otp[index]}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  style={{ width: '40px', textAlign: 'center' }}
                  inputMode="numeric"
                  pattern="\d*"
                />
              ))}
            </div>
          </Form.Item>

          {incorrectOtp && (
            <div className="d-flex justify-content-center">
              <span className="text-danger">
                <ExclamationCircleOutlined className="text-danger me-1" />
                {errorMessage}
              </span>
            </div>
          )}

          <div className="d-flex justify-content-center mt-2">
            {secondsLeft > 0 ? (
              <span className="text-muted">
                Resend in {secondsLeft} second{secondsLeft !== 1 ? 's' : ''}
              </span>
            ) : (
              <button className="link-button shadow-none text-decoration-none" onClick={handleResend}>
                Resend
              </button>
            )}
          </div>

          <div className="d-flex justify-content-end align-items-center gap-3">
            <button type="button" className="twoFAButton border-0 bg-transparent shadow-none" onClick={handleCancel}>
              Cancel
            </button>

            <Button iconPosition="end" className={`${!validateOtp() ? 'disabledButton' : ''}`} type="primary" htmlType="submit" disabled={!validateOtp()}>
              Verify
            </Button>
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default VerifyOtp;
