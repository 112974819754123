import { Button, Card, Form, Input, message, notification, Spin, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CloseOutlined, MailOutlined, PhoneOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfileLoading, updateProfileNameAsync, updateProfilePictureAsync } from '../../../redux/reducers/slices/UserProfile/UserProfileSlice';
import { getNotificationData } from '../../../redux/actions/Header/getNotificationData.action';
import CommonAvatar from '../../../utils/CommonAvatar';
import { ChannelPartnerType, UserTypes } from '../../common/userTypes';
import { CapitalizeString, FetchKey, FormatType } from '../../../utils';

const Profile = () => {
  const notificationDta = useSelector((state) => state.getNotificationDataReducer);
  const userData = notificationDta?.data?.result?.userData;

  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState(null);

  const [isChanged, setIsChanged] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [formChanged, setFormChanged] = useState(false);
  const [avatarChanged, setAvatarChanged] = useState(false);

  const displayRole = userData?.channel_partner_type;
  const displayCompanyName = userData?.company_name;
  const displayCrnNumber = userData?.mortgage_broker_uni_number;

  // Initial values
  const initialFormValues = useRef({});
  const initialImageUrl = useRef(null);
  const initialFileList = useRef([]);

  const dispatch = useDispatch();

  const userProfileLoading = useSelector(selectUserProfileLoading);

  const changePictureText = () => {
    if (imageUrl) {
      if (userData?.user_type === UserTypes.USER || userData?.user_type === UserTypes.SUB_TAXACCOUTANT || userData?.user_type === UserTypes.SUB_CHANNEL_PARTNER) {
        return 'Change Profile Picture';
      } else {
        return 'Change Company Logo';
      }
    } else {
      if (userData?.user_type === UserTypes.USER || userData?.user_type === UserTypes.SUB_TAXACCOUTANT || userData?.user_type === UserTypes.SUB_CHANNEL_PARTNER) {
        return 'Upload Profile Picture';
      } else {
        return 'Upload Company Logo';
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: userData?.name,
      email: userData?.email,
      phone_number: userData?.phone_number,
    });
    if (displayCompanyName) {
      form.setFieldsValue({
        company_name: userData?.company_name,
      });
    }
    if (userData?.channel_partner_type) {
      const role = FormatType(FetchKey(ChannelPartnerType, userData?.channel_partner_type)) || CapitalizeString(FetchKey(ChannelPartnerType, userData?.channel_partner_type));

      form.setFieldsValue({ user_role: role });
    }
    if (displayCrnNumber) {
      form.setFieldsValue({
        crn_number: userData?.mortgage_broker_uni_number,
      });
    }
  }, [userData, form, displayCompanyName, displayCrnNumber]);

  useEffect(() => {
    // Set initial values after form is initialized
    initialFormValues.current = form.getFieldsValue();
    initialImageUrl.current = userData?.profile_image_path || userData?.company_logo;
    initialFileList.current = fileList;

    setImageUrl(userData?.profile_image_path || userData?.company_logo);

    // Run checkIfChanged after setting initial values
    checkIfChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    checkIfChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl, fileList]);

  const clearFileList = () => {
    setFileList([]);
  };

  const handleFinish = async (values) => {
    const { name } = values;
    let formUpdateSuccess = false;

    let avatarUpdateSuccess = false;

    if (avatarChanged && !formChanged) {
      try {
        const formData = new FormData();
        formData.append('file', fileList[0]?.originFileObj);
        const res = await dispatch(updateProfilePictureAsync(formData));
        if (res?.payload?.data?.success) {
          notification.success({
            message: 'Success',
            description: res?.payload?.data?.message,
          });
          clearFileList();
          await dispatch(getNotificationData());
        } else {
          notification.error({
            message: 'Failure',
            description: res?.payload?.data?.message || 'Something went wrong!',
          });
        }
      } catch (error) {
        notification.error({
          message: 'Failure',
          description: error.message || 'Something went wrong!',
        });
      }
    }

    if (formChanged && !avatarChanged) {
      try {
        const res = await dispatch(updateProfileNameAsync({ name }));
        if (res?.payload?.data?.success) {
          notification.success({
            message: 'Success',
            description: res?.payload?.data?.message,
          });
          await dispatch(getNotificationData());
        } else {
          notification.error({
            message: 'Failure',
            description: res?.payload?.data?.message || 'Something went wrong!',
          });
        }
      } catch (error) {
        notification.error({
          message: 'Failure',
          description: error.message || 'Something went wrong!',
        });
      }
    }

    if (formChanged && avatarChanged) {
      try {
        const formData = new FormData();
        formData.append('file', fileList[0]?.originFileObj);
        const res = await dispatch(updateProfilePictureAsync(formData));
        if (res?.payload?.data?.success) {
          avatarUpdateSuccess = true;
        } else {
          avatarUpdateSuccess = false;
        }
      } catch (error) {
        avatarUpdateSuccess = false;
      }

      try {
        const res = await dispatch(updateProfileNameAsync({ name }));
        if (res?.payload?.data?.success) {
          formUpdateSuccess = true;
        } else {
          formUpdateSuccess = false;
        }
      } catch (error) {
        formUpdateSuccess = false;
      }

      if (formUpdateSuccess && avatarUpdateSuccess) {
        await dispatch(getNotificationData());
        notification.success({ message: 'Success', description: 'Profile updated successfully!' });
      } else if (formUpdateSuccess && !avatarUpdateSuccess) {
        await dispatch(getNotificationData());
        notification.error({ message: 'Failure', description: 'Profile picture update failed!' });
      } else if (!formUpdateSuccess && avatarUpdateSuccess) {
        await dispatch(getNotificationData());
        notification.error({ message: 'Failure', description: 'Profile update failed!' });
      } else {
        notification.error({ message: 'Failure', description: 'Profile update failed!' });
      }
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleUploadChange = async ({ file, fileList }) => {
    if (fileList?.length > 0 && !['image/png', 'image/jpg', 'image/jpeg'].includes(fileList[0]?.type)) {
      return false;
    }

    if (file && file.originFileObj) {
      const previewUrl = URL.createObjectURL(file.originFileObj);
      setImageUrl(previewUrl);
    } else {
      setImageUrl(null);
    }
    setFileList(fileList);
  };

  const uploadProps = {
    onChange: handleUploadChange,
    fileList,
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const beforeUpload = (file) => {
    const isLt10Mb = file.size / 1024 / 1024 < 10;
    if (!isLt10Mb) {
      message.error('File must be smaller than 10MB!');
      return Upload.LIST_IGNORE;
    }
    return isLt10Mb;
  };

  const handleRemoveImage = () => {
    setImageUrl(userData?.profile_image_path);
    setFileList([]);
  };

  const checkIfChanged = () => {
    const currentFormValues = form.getFieldsValue();
    const isFormChanged = currentFormValues.name !== initialFormValues.current.name;
    setFormChanged(isFormChanged);

    const isAvatarChanged = imageUrl !== initialImageUrl.current || fileList.length !== initialFileList.current.length;
    setAvatarChanged(isAvatarChanged);

    setIsChanged(isFormChanged || isAvatarChanged);
  };

  const handleFormChange = () => {
    checkIfChanged();
  };

  return (
    <Card bordered={false} className="shadow-none profile-content-card " title="Profile">
      <Spin spinning={notificationDta?.isLoading || userProfileLoading}>
        <div className="d-flex flex-column gap-1">
          {/* <label className="ant-form-item-required-mark-optional">Profile Image</label> */}
          <div className="d-flex mt-2 mb-2 align-items-center gap-3">
            <CommonAvatar size={144} profile={true} src={imageUrl} otherSrc={true} />

            <div className="d-flex align-items-center justify-content-between gap-3">
              <ImgCrop
                beforeCrop={(file) => {
                  if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file?.type)) {
                    notification.error({
                      message: 'Failure',
                      description: 'You can upload image formats .jpg, .jpeg, .png only!',
                    });
                    setFileList([]);
                    return false;
                  }
                }}
                rotationSlider
                showReset
              >
                <Upload
                  fileList={fileList}
                  accept=".jpg, .jpeg, .png, image/jpeg, image/png"
                  className="w-25"
                  onChange={handleUploadChange}
                  onPreview={onPreview}
                  customRequest={dummyRequest}
                  {...uploadProps}
                  maxCount={1}
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>{changePictureText()}</Button>
                </Upload>
              </ImgCrop>

              {imageUrl && avatarChanged && (
                <Button onClick={handleRemoveImage} danger icon={<CloseOutlined />}>
                  Remove
                </Button>
              )}
            </div>
          </div>
          <Form layout="vertical" requiredMark={'optional'} form={form} className="mt-2" onFinish={handleFinish} onValuesChange={handleFormChange}>
            <Form.Item
              label="Full Name"
              name="name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter your full name!',
                },
              ]}
            >
              <Input prefix={<UserOutlined />} size="small" />
            </Form.Item>
            <Form.Item label="Email" required={'optional'} name="email" className="cursor-default">
              <Input readOnly className="cursor-default" prefix={<MailOutlined />} size="small" />
            </Form.Item>
            {displayCompanyName && (
              <Form.Item label="Company Name" required={'optional'} name="company_name" className="cursor-default">
                <Input readOnly className="cursor-default" size="small" />
              </Form.Item>
            )}
            {displayCrnNumber && (
              <Form.Item label="CRN Number" required={'optional'} name="crn_number" className="cursor-default">
                <Input readOnly className="cursor-default" size="small" />
              </Form.Item>
            )}
            {displayRole && (
              <Form.Item label="Role" required={'optional'} name="user_role" className="cursor-default">
                <Input readOnly className="cursor-default" size="small" />
              </Form.Item>
            )}
            <Form.Item label="Phone Number" required={'optional'} name="phone_number" className="cursor-default">
              <Input readOnly className="cursor-default" prefix={<PhoneOutlined style={{ color: 'black', transform: 'rotate(90deg)' }} />} size="small" />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" className={`${!isChanged ? 'text-muted' : ''}`} type={`${!isChanged ? '' : 'primary'}`} disabled={!isChanged}>
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Card>
  );
};

export default Profile;
