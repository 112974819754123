import { AppstoreOutlined, BarsOutlined, FrownOutlined, SmileOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Card, Descriptions, List, Modal, notification, Popconfirm, Segmented, Spin, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppIcon } from '../../../assets/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { GetBankConnect } from '../../../redux/actions/dashboard/getBankConnect.action';
import { ManageConsent } from '../../../redux/actions/Header/ManageConsent.action';
import { DeleteConnections } from '../../../redux/actions/BankConnection/DeleteConnection.action';
import { PostBankConnect } from '../../../redux/actions/dashboard/postBankConnect.action';
import { useSearchParams } from 'react-router-dom';
import {
  getBankDetailsAsync,
  getCardDataAsync,
  getListDataAsync,
  ResetBankDetails,
  selectBankDataLoading,
  selectBankDetails,
  selectCardData,
  selectListData,
  selectViewDetailsLoading,
} from '../../../redux/reducers/slices/BankConnection/BankConnectionSlice';
import BankList from './banklist';

import dayjs from 'dayjs';
import './index.less';
import { updateConsent } from '../../../redux/actions/dashboard/consent.action';
import Consent from './consent';
import { BankAccountTypes, BankConnectionJobStatus } from '../../common/userTypes';
import { formatCurrency } from '../../../utils';
import '../../../assets/css/antd.css';

const BankConnection = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const cardDataSelector = useSelector(selectCardData);
  const cardData = cardDataSelector?.bank_data || [];

  const accountDetails = useSelector(selectBankDetails);

  const accountDetailsLoading = useSelector(selectBankDataLoading);

  const viewDetailsLoading = useSelector(selectViewDetailsLoading);

  const [layoutView, setLayoutView] = useState('card');
  const bankListData = useSelector(selectListData)?.bank_data || [];
  const [checkLoad1, setCheckLoad1] = useState(false);

  const [connectLoading, setConnectLoading] = useState(false);

  const [bankName, setBankName] = useState([]);

  const [modalBankName, setModalBankName] = useState('');
  const [modalBankLogoUrl, setModalBankLogoUrl] = useState('');
  const [modalBankAccountNumber, setModalBankAccountNumber] = useState('');

  const [isBankInfoModalOpen, setIsBankInfoModalOpen] = useState(false);

  const [isOpenDis, setIsOpenDis] = useState(false);
  const [referspage, setReferspage] = useState(true);
  const [disconnectId, setDisconnectId] = useState([]);

  const [searchParams] = useSearchParams();
  const Jobid = searchParams.get('jobId');
  const basiq_state = searchParams.get('state');

  useEffect(() => {
    if (layoutView === 'card') {
      dispatch(getCardDataAsync());
    } else if (layoutView === 'list') {
      dispatch(getListDataAsync());
    }
  }, [referspage, layoutView, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (['reauthorize'].includes(basiq_state)) {
        setConnectLoading(true);
        try {
          const res = await dispatch(updateConsent(basiq_state));
          if (res.payload.success) {
            window.open(res.payload.result.connect, '_self');
          } else {
            notification.open({
              message: 'Failure',
              description: res.payload.message || 'Something went wrong!',
              icon: <FrownOutlined style={{ color: 'red' }} />,
            });
          }
        } catch (error) {
          notification.open({
            message: 'Failure',
            description: error.message || 'Something went wrong!',
            icon: <FrownOutlined style={{ color: 'red' }} />,
          });
          setConnectLoading(false);
        }
      } else if (Jobid && Jobid !== 'null') {
        setConnectLoading(true);
        try {
          const res = await dispatch(PostBankConnect({ jobId: Jobid }));
          if (res.payload.success) {
            dispatch(getCardDataAsync());
            switch (res.payload.result.status) {
              case BankConnectionJobStatus.JOB_COMPLETED:
                notification.success({
                  message: 'Success',
                  description: 'Bank connected successfully',
                });
                break;
              case BankConnectionJobStatus.JOB_FAILED:
                notification.error({
                  message: 'Failed',
                  description: 'Bank connection failed, Please try again!',
                });
                break;
              case BankConnectionJobStatus.JOB_PENDING:
                notification.info({
                  message: 'In-Progress',
                  description: 'Bank connection in Progress',
                });
                break;
              default:
                break;
            }
            setConnectLoading(false);
            navigate('/bank');
          } else {
            notification.open({
              message: 'Failure',
              description: res.payload.message || 'Something went wrong!',
              icon: <FrownOutlined style={{ color: 'red' }} />,
            });
            setConnectLoading(false);
            navigate('/bank');
          }
        } catch (error) {
          notification.open({
            message: 'Failure',
            description: error.message || 'Something went wrong!',
            icon: <FrownOutlined style={{ color: 'red' }} />,
          });
          navigate('/bank');
          setConnectLoading(false);
        }
      } else if (Jobid === 'null') {
        setConnectLoading(false);
        navigate('/bank');
      }
    };

    fetchData();
  }, [Jobid, basiq_state, dispatch, navigate]);

  const viewBankDetails = (ele, item) => {
    setModalBankName(ele.bank_name);
    setModalBankLogoUrl(ele.bank_logo);
    setModalBankAccountNumber(item.mask_account_number);

    const { id } = item;
    dispatch(getBankDetailsAsync(id));
    setIsBankInfoModalOpen(true);
  };

  const handleDetailsModalClose = () => {
    setModalBankName('');
    setModalBankLogoUrl('');
    setModalBankAccountNumber('');
    setIsBankInfoModalOpen(false);
    dispatch(ResetBankDetails());
  };

  const BankConnect = (id, type) => {
    setConnectLoading(true);
    dispatch(GetBankConnect(id, type)).then((res) => {
      if (res.payload.success === true && res.payload.result.connect !== null && res.payload.result.connect !== '' && typeof res.payload.result.connect === 'string') {
        window.open(res.payload.result.connect, '_self');
        setConnectLoading(false);
      } else {
        notification.open({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
          icon: <FrownOutlined style={{ color: 'red' }} />,
        });
        setConnectLoading(false);
      }
    });
  };

  const DeleteConsent = () => {
    setConnectLoading(true);
    dispatch(ManageConsent()).then((res) => {
      if (res.payload.success === true) {
        notification.open({
          message: 'Success',
          description: res.payload.message,
          icon: <SmileOutlined style={{ color: '#56AAAA' }} />,
        });
        setConnectLoading(false);
        setReferspage(!referspage);
      } else {
        notification.open({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
          icon: <FrownOutlined style={{ color: 'red' }} />,
        });
        setConnectLoading(false);
      }
    });
  };

  const diaconnectOpen = (ele) => {
    setBankName(ele.bank_name);
    setDisconnectId(ele.bc_id);
    setIsOpenDis(true);
  };

  const deleteConnection = () => {
    setCheckLoad1(true);
    dispatch(DeleteConnections(disconnectId)).then((res) => {
      if (res.payload.success === true) {
        notification.open({
          message: 'Success',
          description: res.payload.message,
          icon: <SmileOutlined style={{ color: '#56AAAA' }} />,
        });
        setIsOpenDis(false);
        setReferspage(!referspage);
        setCheckLoad1(false);
      } else {
        notification.open({
          message: 'Failure',
          description: res.payload.message || 'Something went wrong!',
          icon: <FrownOutlined style={{ color: 'red' }} />,
        });
        setCheckLoad1(false);
      }
    });
  };

  return (
    <>
      <main className="page-content">
        <div className="container-fluid bg-dash-back">
          <div className="layout-specing">
            <div className="row align-items-center mt-1">
              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                <div className="text-center text-sm-start mb-sm-0 mb-3 mb-sm-0">
                  <h5 className="m-0">Bank List</h5>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-6 col-12">
                <div className="text-center text-sm-end justify-content-between">
                  {cardData && cardData.length > 0 && (
                    <Segmented
                      options={[
                        {
                          value: 'card',
                          label: 'Card',
                          icon: <AppstoreOutlined />,
                        },
                        {
                          value: 'list',
                          label: 'List',
                          icon: <BarsOutlined />,
                        },
                      ]}
                      size="large"
                      className="mr-2"
                      onChange={(value) => setLayoutView(value)}
                    />
                  )}

                  {cardDataSelector?.delete_consent === true ? (
                    <Popconfirm
                      width={200}
                      okText={<h6 style={{ color: 'white', fontWeight: '700' }}>Yes</h6>}
                      cancelText={<h6 style={{ color: 'black', fontWeight: '600' }}>No</h6>}
                      placement="leftBottom"
                      title={
                        <label className="text-muted Small_text">
                          This will remove:
                          <ul>
                            <li> Your consent for direct data feed of your loan accounts from all banks,</li>
                            {cardData?.bank_data?.map((BankItems) => {
                              return (
                                <ul key={crypto.randomUUID()}>
                                  <li>{BankItems.bank_name}</li>
                                </ul>
                              );
                            })}
                            <li> All your past and present data received by BASIQ as accredited data recipient will stop all future data feed from your banks.</li>

                            <li> To remove all your data with ‘The Property Accountant’, you will need to delete your account from your user profile section</li>
                          </ul>{' '}
                        </label>
                      }
                      onConfirm={() => DeleteConsent()}
                    >
                      <Button className="property_btn1">Delete All</Button>
                    </Popconfirm>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className="card_wrapper Bank_card">
              <Consent setConnectLoading={setConnectLoading} bdata={cardDataSelector} />
              {layoutView === 'card' && (
                <Spin spinning={accountDetailsLoading || connectLoading} size="large">
                  <div className="row">
                    <div className="cardList bankCard mt-4">
                      {cardData?.map((ele, i) => {
                        const bank_connect = ele?.account?.some((item) => item.connect === 0);
                        const bank_disconnect = ele?.account?.some((item) => item.connect === 1);
                        return (
                          <Card
                            className={'projectList box_shadow'}
                            style={{ marginBottom: 24 }}
                            styles={{
                              body: {
                                padding: '0 8px 0 8px',
                              },
                            }}
                            key={i}
                            title={
                              <>
                                <img src={ele.bank_logo} style={{ height: 24, width: 24, borderRadius: '4px' }} className="img-fluid" alt="" />
                                &nbsp; {ele.bank_name} &nbsp;
                                {ele.display_type && ele.ba_type && (
                                  <span>
                                    (
                                    {
                                      Object.entries(BankAccountTypes)
                                        .find(([key, val]) => val === Number(ele.ba_type))?.[0]
                                        .split(' ')[0]
                                    }
                                    )
                                  </span>
                                )}
                              </>
                            }
                            bordered={false}
                            extra={
                              <>
                                {bank_connect ? (
                                  <Button className=" Connect_btn me-2" onClick={() => BankConnect(ele.bank_id, ele.ba_type)}>
                                    Connect
                                  </Button>
                                ) : (
                                  ''
                                )}
                                {bank_disconnect ? (
                                  <Button className="DisConnect_btn" onClick={() => diaconnectOpen(ele)}>
                                    Disconnect
                                  </Button>
                                ) : (
                                  ''
                                )}
                              </>
                            }
                          >
                            <List
                              rowKey="id"
                              grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 2,
                                md: 2,
                                lg: 3,
                                xl: 3,
                                xxl: 4,
                              }}
                              dataSource={ele.account}
                              renderItem={(item) => {
                                if (item && item) {
                                  return (
                                    <Card.Grid className={'projectGrid'} key={item.id}>
                                      <Card
                                        styles={{
                                          body: {
                                            padding: 0,
                                          },
                                        }}
                                        bordered={false}
                                        actions={[
                                          <>
                                            {item.connect ? (
                                              <>
                                                <Badge size={'small'} status="success" styles={{ fontSize: '40px' }} /> <span className="bank-status">Connected</span>
                                              </>
                                            ) : (
                                              <>
                                                <Badge status="error" /> <span className="bank-status"> Pending</span>
                                              </>
                                            )}
                                          </>,
                                          <span
                                            style={{ color: '#56aaaa' }}
                                            onClick={() => {
                                              viewBankDetails(ele, item);
                                            }}
                                          >
                                            <u>View Details</u>
                                          </span>,
                                        ]}
                                      >
                                        <Card.Meta
                                          description={
                                            <Descriptions
                                              column={1}
                                              items={[
                                                {
                                                  key: 'acc-no',
                                                  label: 'Account Number',
                                                  children: item.mask_account_number,
                                                },
                                                {
                                                  key: 'loan-bal',
                                                  label: 'Loan Balance',
                                                  children: item.connect ? formatCurrency(item.loan_balance, 0, false, true) : 'N/A',
                                                },
                                                {
                                                  key: 'interest-rate',
                                                  label: 'Interest Rate',
                                                  children: item.connect ? item.interest_rate + '%' : 'N/A',
                                                },
                                              ]}
                                            />
                                          }
                                        />
                                      </Card>
                                    </Card.Grid>
                                  );
                                }
                              }}
                            />
                          </Card>
                        );
                      })}
                      {accountDetailsLoading === false && cardData.length < 1 && (
                        <Card className="box_shadow mt-3">
                          <Empty />
                        </Card>
                      )}
                    </div>
                  </div>
                </Spin>
              )}
              {layoutView === 'list' && (
                <div className="row">
                  <div className="cardList">
                    <BankList list={bankListData} BankConnect={BankConnect} diaconnectOpen={diaconnectOpen} loading={accountDetailsLoading || connectLoading} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      {/* ACCOUNT DISCONNECT MODAL  */}
      <Modal
        title={<h4 className="mb-0">Disconnect</h4>}
        open={isOpenDis}
        footer={
          <div className="d-flex p-2">
            <div className="col-6 col-xl-6 col-sm-6 col-lg-6 text-center close_sharing me-1">
              <Button className="close_sharing" onClick={() => deleteConnection()}>
                Stop sharing
              </Button>
            </div>
            <div className="col-6 col-xl-6 col-sm-6 col-lg-6 text-center close_sharing me-1">
              <Button className="close_sharing" onClick={() => setIsOpenDis(false)}>
                Close
              </Button>
            </div>
          </div>
        }
        width={500}
        onCancel={() => setIsOpenDis(false)}
      >
        <Spin spinning={checkLoad1}>
          <div className="row">
            <div className="d-flex justify-content-center text-center">
              <span className="big">
                <AppIcon height="60" className="logo-light-mode" />
              </span>
            </div>
          </div>

          <div className="row">
            <div>
              <p className="mb-1 mt-4 text-muted" style={{ fontSize: '14px' }}>
                This will remove your connection for direct data feed of your loan accounts from " <span style={{ color: '#0d4444' }}>{bankName}</span> ".
              </p>
              <p className="text-muted" style={{ fontSize: '14px' }}>
                {' '}
                You will need to reconnect again if you like to connect direct feed of your loan accounts later.
              </p>
            </div>
          </div>
        </Spin>
      </Modal>

      <Modal
        title={
          <>
            <img src={modalBankLogoUrl || accountDetails?.bank_logo} style={{ height: 24, width: 24, borderRadius: '4px' }} className="img-fluid" alt="" />
            &nbsp; {modalBankName || accountDetails?.bank_name} ({modalBankAccountNumber || accountDetails?.mask_account_number})
          </>
        }
        open={isBankInfoModalOpen}
        onCancel={() => handleDetailsModalClose()}
        className="bank-info-modal"
        destroyOnClose
        footer={null}
      >
        <Spin spinning={viewDetailsLoading}>
          {!viewDetailsLoading && accountDetails?.connect !== '1' && (
            <>
              <Descriptions title="Loan Details" />
              <Alert description="Please connect your bank account to access all the loan details." type="warning" showIcon style={{ marginBottom: '20px' }} />
            </>
          )}

          {accountDetails.connect === '1' && (
            <Descriptions
              title="Loan Details"
              layout="vertical"
              column={2}
              items={[
                {
                  key: 'Interest-Rate',
                  label: 'Interest Rate',
                  children: <span>{accountDetails?.interest_rate || 0}%</span>,
                },
                {
                  key: 'Interest-Type',
                  label: 'Interest Type',
                  children: accountDetails.interest_type ? <span style={{ textTransform: 'capitalize' }}>{accountDetails.interest_type?.toLowerCase()}</span> : '-',
                },
                {
                  key: 'install-amount',
                  label: 'Repayment',
                  children: accountDetails?.min_instalment_amount && accountDetails?.min_instalment_amount !== '0' ? `$${accountDetails?.min_instalment_amount}` : '-',
                },
                {
                  key: 'Repayment-Type',
                  label: 'Repayment Type',
                  children: accountDetails?.loan_replacement_type ? (
                    <span style={{ textTransform: 'capitalize' }}>{accountDetails?.loan_replacement_type?.replace(/_/g, ' ').toLowerCase()}</span>
                  ) : (
                    '-'
                  ),
                },
                {
                  key: 'Available Redraw',
                  label: 'Available-Redraw',
                  children: `$${accountDetails?.available_funds || 0}`,
                },
                {
                  key: 'Repayment-Frequency',
                  label: 'Repayment Frequency',
                  children: accountDetails?.loan_application_frequency || '-',
                },
                {
                  key: 'Start-Date',
                  label: 'Start Date',
                  children: accountDetails?.loan_start_date ? dayjs(accountDetails?.loan_start_date).format('DD/MM/YYYY') : '-',
                },
                {
                  key: 'End-Date',
                  label: 'End Date',
                  children: accountDetails?.loan_end_date ? dayjs(accountDetails?.loan_end_date).format('DD/MM/YYYY') : '-',
                },
                {
                  key: 'product_name',
                  label: 'Product Name',
                  children: accountDetails?.product_name || '-',
                },
              ]}
            />
          )}

          {accountDetails?.loan_fees && (
            <Descriptions
              title="Fees & Charges"
              layout="vertical"
              column={2}
              items={accountDetails?.loan_fees?.map((item, i) => {
                return {
                  key: i,
                  label: item.name,
                  children: `$${item.amount || 0}`,
                };
              })}
            />
          )}

          {accountDetails?.property && (
            <Descriptions
              title="Properties"
              column={1}
              items={accountDetails?.property?.map((item, i) => {
                return {
                  key: i,
                  label: `${item.street_number} ${item.street_name}`,
                  children: `${Number(item.interest_allocation || 0)}%`,
                };
              })}
            />
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default BankConnection;
